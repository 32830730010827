import Vue from 'vue'
import VueRouter from 'vue-router'
import GateAuth from '../views/gate/GateAuth.vue'
import Home from '../views/Home.vue'
import Overview from '../views/dashboard/overview/Overview.vue'
import Settings from '../views/dashboard/settings/Settings.vue'
import NewsSnippets from '../views/dashboard/databank/NewsSnippets.vue'
import Roadmap from '../views/dashboard/operations/Roadmap.vue'
import Budgeting from '../views/dashboard/operations/Budgeting.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'GateAuth',
    component: GateAuth
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'Overview',
        component: Overview
      },
      {
        path: '/roadmap',
        name: 'Roadmap',
        component: Roadmap
      },
      {
        path: '/budget',
        name: 'Budgeting',
        component: Budgeting
      },
      {
        path: '/news-snippets',
        name: 'NewsSnippets',
        component: NewsSnippets
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings
      },
    ]
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
