<template>
  <div id="Roadmap">
    <h2>Roadmap</h2>
    <hr />
    <div class="roadmap-area">
      <section class="roadmap-line">
        <div class="line"></div>
        <div class="circle"></div>
        <span class="clock">
          <p>{{current_time}}</p>
          <p>{{current_date}}</p>
        </span>
      </section>

      <section class="map-points">
        <div class="tab-years">
          <div
          class="tab-date"
          :class="{'current-year': year == currentYear}"
          v-for="(year, idx) in roadmapYears"
          :key="'current'+idx"
          @click="setViewYear(year)">
            <h1>{{year}}</h1>
          </div>
        </div>

        <div class="points" v-for="(quarter, idx) in sortedRoadmap" :key="'Q'+idx">
          <h2>Q{{idx+1}}</h2>
          <div
              class="point"
              v-for="(rm, idx) in quarter"
              :key="idx">
            <span class="progression" v-if="rm.fulfilled">✅</span>
            <span class="progression" v-else>📝</span>
            <p>{{rm.note}}</p>
            <!-- {{$fmtDate(rm.epoch)}} -->
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
// import {rtdb} from '@/_firebase/init.js'
import {mapState} from 'vuex'
import roadmap_points from '@/assets/json/roadmap_points.json'

export default {
  components:{
  },
  name: 'Roadmap',
  data(){
    return{
      roadmap: roadmap_points,
      currentYear: 2021,
      roadmapYears: [2021,2022,2023,2024, 2025],
      current_time: '',
      current_date: ''
    }
  },
  computed:{
    ...mapState(['app_version_number']),
    sortedRoadmap(){
      let new_roadmap = [[], [], [], []]
      for(let r of this.roadmap ){
        console.log(r)
        if(r.expectedFrame.year == this.currentYear){
          new_roadmap[r.expectedFrame.quarter-1].push(r)
        }
      }
      return new_roadmap.sort((a, b) => b.created - a.created)
    }
  },
  methods:{
    setViewYear(y){
      this.currentYear = y
    },
    setClock(){
      setInterval(()=> {
        var d = new Date(); // The 0 there is the key, which sets the date to the epoch
        let date_num = d.getDate()
        let month = d.getMonth()
        let year = d.getFullYear()
        let hour = d.getHours()
        let second = d.getSeconds();
        let minute = d.getMinutes()
        let fmtMin = `${minute}`
        let fmtSec = `${second}`
        if(minute < 10){
          fmtMin = `0${minute}`
        }
        if(second < 10){
          fmtSec = `0${second}`
        }
        this.current_time =  `${hour}:${fmtMin}:${fmtSec}`
        this.current_date =  `${month+1}/${date_num}/${year}`
      },1000)
    }
  },
  mounted(){
    this.setClock()
  }
}
</script>
<style scoped>
#Roadmap{
  width: 100%;
  margin: 1rem 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.tab-years{
  display: flex;
}
.tab-date{
  cursor:pointer;
  background-color: #ddd;
  padding: .2rem 1.4rem;
}
.current-year{
  background-color: #fff;
  border: 2px solid #ddd;
  color: var(--primary);
}
.map-points{
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.points{
  margin-left: 1rem;
}
.point{
  display: flex;
  align-items:center;
  margin-left: 2rem;
  transition: 1s;
  font-size: 1.2rem;
  font-weight: bold;
}
.progression{
  font-size: 2rem;
}
.roadmap-area{
  margin: 0 1rem;
  display: flex;
  height: 100%;
  width: 100%;
}
.roadmap-line{
  min-height: 100vh;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items:center;
}
.line{
  width: .4rem;
  min-height: 3rem;
  height: 100%;
  border: .5px solid #000;
}
.circle{
  width: .8rem;
  height: .8rem;
  border-radius: 100%;
  background-color: #d8403b;
  box-shadow: 0 0 0 0 rgba(#e47a75, .5);
  -webkit-box-shadow: 0 0 0 0 rgba(#e47a75, .5);
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 2s infinite;
}
.clock{
  text-align:center;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#d8403b, 0.7);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 20px rgba(#e47a75, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(#e47a75, 0);
  }
}
@media only screen and (max-width: 900px) {
  .non-mobile{
    display: none;
  }
}
</style>
