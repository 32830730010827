<template>
  <div class="overview">
    <h1>Welcome Quantumfy Team</h1>
    <p>What are you looking for today?</p>

    <div class="utility-belt">
      <div
      class="card"
      :class="{'inactive-link': card.route == ''}"
      v-for="(card, idx) in utilities"
      :key="idx"
      @click="gotoRoute(card.route, card.internalPage)"
      >
        <img :src="require(`@/assets/icons/overview-cards/${card.icon}`)" />
        <div class="card-details">
          <h2>{{card.name}}</h2>
          <p v-if="!isMobile">{{card.description}}</p>
        </div>
      </div>
    </div>
    <!-- <h2>Software Versions:   <router-link :to="{name: 'VersionControl'}" class="primary-btn">Version Control</router-link></h2>
    <UserSearch />
    <br />
    <hr />
    <TagPanel />

    <AppLog /> -->
  </div>
</template>

<script>
// import UserSearch from "@/components/dashboard/overview/UserSearch.vue";
// import TagPanel from './TagPanel'
// import AppLog from '@/components/dashboard/users/AppLog.vue'
import utilities_menu from '@/assets/json/utilities_menu.json'

export default {
  name: 'Overview',
  components:{
  },
  props:['isMobile'],
  data(){
    return{
      utilities: utilities_menu
    }
  },
  computed:{

  },
  methods:{
    gotoRoute(route, internal){
      if(!internal){
        window.open(route)
      }else{
        this.$router.push({name: route})
      }
    }
  },
  created(){
    console.log(utilities_menu)
  }
}
</script>
<style scoped>
.utility-belt{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  transition: 1s;
}
.card-details{
  font-size: .8rem;
  width: 80%;
  margin: 0 auto;
}
.card{
  display: flex;
  flex-direction: column;
  text-align:center;
  justify-content:center;
  align-items: center;
  transition: 1s;
  border-radius: 20px;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow:  5px 5px 10px #8f8f8f,
               -5px -5px 10px #ffffff;
  cursor: pointer;
}
.card:hover{
  transform: translateY(-2px);
  box-shadow:  5px 5px 10px var(--primary),
               -5px -5px 10px #ffffff;
}
.card img{
  width: 60%;
  margin: 1rem auto;
  max-width: 8rem;
}
.inactive-link{
  opacity: .3;
  background: linear-gradient(145deg, #999, #6f6f6f);
}
.inactive-link img{
  filter: grayscale(100%);
}
@media only screen and (max-width: 600px) {
  .utility-belt{
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
}
</style>
