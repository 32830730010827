let news_snippet = window.localStorage.getItem('news_snippet');

const core_menu = [
  { name: 'Overview', route: 'Overview', icon: require('@/assets/icons/sidemenu/dashboard.svg'), style: {} },
  // { name: 'Users', route: 'Users', icon: require('@/assets/icons/sidemenu/user.svg'), style: {} },
  { name: 'NewsSnippets', route: 'NewsSnippets', icon: require('@/assets/icons/sidemenu/shopping-bag.svg'), style: {}},
  { name: 'Settings', route: 'Settings', icon: require('@/assets/icons/sidemenu/support.svg'), style: {} },
  { name: 'Logout', route: 'GateAuth', icon: require('@/assets/icons/sidemenu/logout.svg'), style: {} },
]
export default {
  //Main
  version_number: 'v0.1.0',
  app_version_number: 'v0.2.5',
  auth: false,
  news_snippet: news_snippet ? JSON.parse(news_snippet) : {title: '', dateFMT: '',source_author: '', website: '',link: ''},
  sideMenu: core_menu,
  processing: false,
  feedback: null,
  feedtype: ''
}
