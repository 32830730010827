<template>
  <div id="app">
    <router-view :isMobile="isMobile"/>
  </div>
</template>
<script>
// import {mapState} from 'vuex'

export default {
  name: 'app',
  computed: {
    // ...mapState(['theme']),
  },
  data(){
    return{
      isMobile: false,
      window:{},
      root:{style:{}},
      dark: false,
    }
  },
  watch:{
    window:{
      handler(nv){
        if(nv.width < 600){
          this.isMobile = true
          // console.log(nv.width)
        }else{
          this.isMobile = false
        }
      },
      deep: true
    },
  },
  methods:{
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  created() {
    this.window = {
      'width': window.innerWidth,
      'height': window.innerHeight
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  mounted: function() {
    this.root = document.documentElement;
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
html, body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}
body{
  /* overflow: hidden; */
  height: 100vh;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
/*---------------------------------*\
|             THEMES
\_--------------------------------*/

:root{
    --primary: #000;;
    --secondary: #2EB6B6;
    --tertiary: #F0592A;
    --success: #85e085;
    --bg-default: #FFFFFF;
    --dark: #000;
    --bg-secondary: #dedede;
    --link-light: #00AFF0;
    --link-dark: #11607E;
    --bg-dark: #545454;
}
.primary-btn{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  background: #3463DC;
  text-align:center;
  padding: 1rem 2rem;
  font-size:.9rem;
  border:none;
  margin: .5rem;
  color: #fff;
  border-radius: 10px;
  transition: 1s;
  cursor: pointer;
}
.input-group{
  display:flex;
  border-radius: 15px;
  border: 1px solid #cecece;
  padding: .6rem 2rem;
  padding-left: 1rem;
  width: 90%;
  min-height: 1.5rem;
  font-size: .8rem;
  margin: 1rem 0;
  transition: 1s;
}
.default-input:focus{
    outline: none;
}
@media screen and (max-width: 540px) {
   body{
    overflow-x: hidden;
  }
}
</style>
