<template>
  <div id="NewsSnippets">
    <div class="dashboard-header">
      <h2>News Snippet</h2>
    </div>
    <section>
      <h2>Telegram Bot Submissions</h2>
      <div class="flex justify-evenly ">
        <div class="w-50">
          <a
          href="https://www.google.com/alerts#2:0"
          target="_blank"
          class="google-alert">
            <img src="@/assets/icons/news-snippet/google.svg" class="w-10"/>
            For ideas
          </a>
        </div>
        <div class="w-50">
        <a
          href="https://thequantuminsider.com/"
          target="_blank"
          class="google-alert">
            <img src="@/assets/icons/news-snippet/tqi-classic.png" class="w-10"/>
            For ideas
        </a>
        </div>
      </div>
      <p :class="{'success': res_stat == 'success'}">{{feedback}}</p>

      <div class="telegram-form" v-if="!processing">

        <div class="input-group-emj">
          <span class="emoji">📰</span>
          <input
            v-model="snippet.title"
            placeholder="Enter Title"/>
        </div>

        <div class="input-group-emj date-inp">
          <span class="emoji">📅</span>
          <input
            v-model="snippet.dateFMT"
            placeholder="Enter Formatted Date"/>
            <button class="now-btn" @click="selectToday()">@Today</button>
        </div>

        <div class="input-group-emj">
          <span class="emoji">✍️</span>
          <input
            v-model="snippet.source_author"
            placeholder="Enter Source"/>
        </div>

        <div class="input-group-emj">
          <span class="emoji">🌐</span>
          <input
            v-model="snippet.website"
            placeholder="Enter Website"/>
        </div>

        <div class="input-group-emj">
          <span class="emoji">🔗</span>
          <input
            v-model="snippet.link"
            placeholder="Enter Link to Article"/>
        </div>

      </div>
      <div v-else class="processing-box">
        <lottie-vue-player :src="`https://assets5.lottiefiles.com/packages/lf20_n93ku3mq.json`"
                           :theme="options.theme"
                           :player-size="options.playerSize"
                           :player-controls="false"
                           style="width: 50%; height:200px">
        </lottie-vue-player>
      </div>
      <button @click="validateSend()" class="primary-btn">submit to telegram</button>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'NewsSnippets',
  components:{
  },
  data(){
    return{
      feedback: '',
      res_stat: '',
      processing: false,
      // THE LOTTIE
      options: {
                minimizable: false,
                playerSize: "hide",
                backgroundColor: '#fff',
                backgroundStyle: 'color',
                theme: {
                    controlsView: "standard",
                    active: "light",
                    light: {
                        color: '#3D4852',
                        backgroundColor: '#fff',
                        opacity: '0.7',
                    },
                    dark: {
                        color: '#fff',
                        backgroundColor: '#202020',
                        opacity: '0.7',
                    }
                }
                },
      snippet:{
        title: '',
        dateFMT: '',
        source_author: '',
        website: '',
        link: ''
      }
    }
  },
  methods:{
    ...mapMutations(['ADD_NEWS_SNIPPET']),
    selectToday(){
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

      const d = new Date()
      let date_num = d.getDate()
      let month = d.getMonth()
      let year = d.getFullYear()
      this.snippet.dateFMT = `${months[month]} ${date_num}, ${year}`
    },
    validateSend(){
      this.processing = true
      let validate = true
      this.snippet.link = this.snippet.link.replace('?utm_source=substack&utm_medium=email', "");
      this.ADD_NEWS_SNIPPET(this.snippet)
      
      // console.log('checking')
      if(this.snippet.title == ''){validate = false }
      if(this.snippet.dateFMT == ''){validate = false }
      if(this.snippet.website == ''){validate = false }
      if(this.snippet.link == ''){validate = false }
      this.feedback = "There's an empty field somewhere"
      if (validate){
        this.sendToTelegram()
      }
    },
    sendToTelegram(){
      const payload = this.snippet
      console.log(payload)
      axios.post(this.$api_path + 'admin/send_to_telegram', payload ).then((res)=>{
        console.log(res.data)
        this.feedback = res.data.feedback
        if(res.data.status == 'success'){
          this.snippet = {
            title: '',
            dateFMT: '',
            source_author: '',
            website: '',
            link: ''
          }
          this.ADD_NEWS_SNIPPET(this.snippet)
        }
          this.processing = false
      }).catch((err)=>{
        console.log(err)
          this.processing = false
      })
    }
  },
  computed:{
    ...mapState(['news_snippet'])
  },
  created(){
    if(this.news_snippet.title != ''){
      this.snippet = this.news_snippet
    }
  }
}
</script>
<style scoped>
#NewsSnippets{

}
.telegram-form{
  display: flex;
  flex-direction: column;
  min-width: 80%;
}
.input-group-emj{
  display: flex;
  margin: 1rem 0;
}
.input-group-emj .emoji{
  font-size: 1.5rem;
}
.input-group-emj input{
  padding: .5rem 1rem;
  border-radius: 5px;
  width: 80%;
  border: none;
  background-color: #e3e3e3;
}
.now-btn{
  border:none;
  background-color: var(--secondary);
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: 1s;
}
.now-btn:hover{
  opacity: .8;
}
.success{
  color: var(--secondary);
}
.date-inp{
  width: 90%;
}
.google-alert{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 5px;
  width: 10rem;
  padding: .4rem 1rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--secondary);
  transition: 1s;
}
.google-alert:hover{
  color: var(--primary);
}
.google-alert img{
  width: 2rem;
  margin-top: .25rem;
  margin-right: .5rem;
}
</style>
