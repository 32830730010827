export default {
  ADD_NEWS_SNIPPET(state, news_snippet){
    state.news_snippet = news_snippet
    localStorage.setItem('news_snippet', JSON.stringify(state.news_snippet))
  },
  UPDATE_USER_COUNT(state, usercount) {
    state.appdata.usercount = usercount
    localStorage.setItem('usercount', usercount)
  },
  UPDATE_PET_COUNT(state, petcount) {
    state.appdata.petcount = petcount
    localStorage.setItem('petcount', petcount)
  },
  UPDATE_SELECTED_USER(state, current_user) {
    state.current_user = current_user
    localStorage.setItem('current_user', current_user)
  },
  UPDATE_SELECTED_PET(state, current_pet) {
    state.current_pet = current_pet
    localStorage.setItem('current_pet', current_pet)
  },
  SET_OWNER_ID(state, owner_id) {
    state.owner_id = owner_id;
    window.localStorage.setItem('owner_id', state.owner_id)
  },
  SET_ONBOARDING_POINT(state, onboarding_point) {
    state.onboarding_point = onboarding_point;
    window.localStorage.setItem('onboarding_point', state.onboarding_point)
  },
  // SAVE_NOTE(state, pet_note) {
  //   state.pet_note = pet_note
  //   window.localStorage.removeItem('pet_note')
  // },
  AUTH_SUCCESS(state, token, userID) {
    state.userID = userID
    state.token = token
    state.auth = true;
  },
  AUTH_ERROR(state) {
    state.userID = '';
    state.token = ''
    state.auth = false;
  },
  LOADER(state, payload) {
    state.loader = payload;
  },
  AUTH_LOGOUT(state) {
    state.token = '';
    state.merchanttoken = '';
  },
}
