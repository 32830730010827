<template>
  <div id="GateAuth">

    <div class="login-area">
      <div class="brand-area">
        <img src="@/assets/logo.png" class="logo-brand"/>
        <h2>Quantumfy Admin</h2>
      </div>
      <br />
      <div class="login-form">
        <p class="welcome-text">Welcome Back</p>
        <p>{{feedback}}</p>
        <input class="default-input login-input" type="email"  v-model="email" placeholder="Email"/><br />
        <input class="default-input login-input" type="password" v-model="password" placeholder="Password"/>
        <br />
        <button class="login-btn" @click="login()">Login</button>
      </div>
  </div>

  <div class="flat-mountains" :style="{'backgroundImage': 'url(' + bkgd_img + ')'}"></div>
  </div>
</template>

<script>

export default {
  name: 'GateAuth',
  components: {
  },
  data(){
    return{
      feedback:'',
      email:'',
      password:'',
      bkgd_img: require('@/assets/backgrounds/qc.jpeg'),
    }
  },
  methods:{
    login(){
      if(this.email != 'test@email.com' && this.password != '123123'){
        this.feedback = 'Please enter the password to enter the site'
      }else{
        this.$router.push({name: 'Overview'})
      }
    },
  }
}
</script>
<style scoped>
#GateAuth{
  background-color: #000;
  width: 100%;
  height: 100vh;
  position:relative;
}
.login-area{
  background-color: #fff;
  width: 400px;
  height: 100vh;
  padding:1rem 2rem;
  position: fixed;
  left:0;
  z-index: 2;
  font-size: 1.3rem;
  transition: 1s;
}
.login-form{
  margin-top: 2rem
}
.login-input{
  border-radius: 10px;
  padding: .6rem 2rem;
  width: 60%;
  font-size: 1rem;
  margin: .5rem 0;
  transition: 1s;
}
.login-btn{
  background-color: var(--primary);
  text-align:center;
  border: none;
  border-radius: 10px;
  font-size: 1.3rem;
  padding: 1rem 2rem;
  color: #fff;
  width: 60%;
  margin: 2rem auto;
  cursor: pointer;
  transition: 1s;
}

.signup-link{
  cursor: pointer;
  color: var(--primary);
}
.flat-mountains{
  width: 100%;
  height: 100vh;
  overflow:hidden;
  background-attachment: fixed;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
}
#signup_carousel{
  margin: 2rem auto;
  min-height: 20rem;
}
.example{color: #aaa; font-size: .7rem;}
.brand-area{
  width: 100%;
  text-align:center;
  color: var(--primary);
}
.logo-brand{
  width: 40%;
  margin: 2rem auto;
  transition:1s;
}
.sunsteam-img{
  border-radius: 100%;
  position:absolute;
  width: 250%;
  top: -200%;
  left: -130%;
  opacity: .4;
}
@media only screen and (max-width: 600px) {
  .logo-brand{
    width: 30%;
    margin: 1rem auto;
  }
  .login-area{
    width: 100% !important;
    padding: 0;
  }
  .brand-area{
    margin: 2rem 0;
  }
  .login-form{
    width: 100%;
    margin: 0 auto;
    text-align:center;
  }
}
</style>
