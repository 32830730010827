<template>
  <div id="Budgeting">
    <h2>Budget</h2>
    <hr />
    <div class="filters">
      <div class="expense-filter">
        Break Cost Down By:
        <button class="primary-btn"
        @click="costBreakDown()">{{cost_frame}}</button>
      </div>
      <div class="total-filter">
        Total: ${{(costTotal/denominator).toFixed(2)}}
      </div>
    </div>

    <table class="budget-list">
      <tr>

        <th class="cell">Party</th>
        <th class="cell">Category</th>
        <th class="cell">Expense Amount</th>
        <th class="cell cell2"></th>
      </tr>
      <tr
          class="entry"
          v-for="(item, idx) in filteredList"
          :key="idx">

        <td class="cell">{{item.party}}</td>
        <td class="cell">{{item.category}}</td>
        <td class="cell cell-cash">${{(item.amount/denominator).toFixed(2)}}</td>
        <td class="cell cell2">
          <button class="remove-btn" @click="removeBudget(item._id, idx)">&times;</button>
        </td>
      </tr>
      <tr
          class="entry">

        <td class="cell cell1">
          <input class="input-cell" type="text"
          v-model="new_entry.party"
          placeholder="Enter party"/>
        </td>
        <td class="cell cell1">
          <input class="input-cell"
          type="text"
          placeholder="Enter category"
          v-model="new_entry.category"/>
        </td>
        <td class="cell cell1">
          <input class="input-cell cell-cash" type="number"
          :placeholder="'Enter '+cost_frame.toLowerCase()+' amount'"
          v-model="new_entry.amount"/>
        </td>
        <td class="cell cell2">
          <button class="remove-btn add-btn" @click="addEntry()"><img src="@/assets/icons/budget/add.svg" /></button>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
// import {rtdb} from '@/_firebase/init.js'
import {mapState} from 'vuex'
import axios from 'axios'
import budget_sheet from '@/assets/json/budget_sheet.json'

export default {
  components:{
  },
  name: 'Budgeting',
  data(){
    return{
      cost_frame: 'Annual',
      denominator: 1,
      budget: budget_sheet,
      new_entry: {
        party:'',
        category: '',
        description:'',
        amount: null
      }
    }
  },
  computed:{
    ...mapState(['app_version_number']),
    filteredList(){
      let chrono_list = this.budget
        chrono_list.sort((a, b) => a.created - b.created)
      let full_budget = [...chrono_list]
      let uniq = [...new Set(full_budget)]
      return uniq
    },
    costTotal(){
        let running_total = 0
        let cost_list = this.budget
        for(let cost of cost_list){
          running_total = cost.amount + running_total
        }
        return running_total
    }
  },
  methods:{
    getBudgetData(){
        this.loading = true
        axios.get(this.$api_path + 'admin/budget'
      ).then((res)=>{
        console.log(res.data.feed)
        this.budget = res.data.feed
        this.loading = false
      }).catch((err)=>{
        console.log(err)
      })
    },
    addEntry(){
      const now = new Date()
      const epoch = Math.round(now.getTime() / 1000)
      const data = {
       party: this.new_entry.party,
       amount: (this.new_entry.amount * this.denominator),
       created: epoch,
       description: this.new_entry.description,
       frequency: "annually",
       category: this.new_entry.category
     }
      // let budgetDB = rtdb.ref(`/budget`).push()
      // budgetDB.set(data);

      this.budget.push(data)
      this.new_entry = {
        party:'',
        category: '',
        description:'',
        amount: 0
      }
    },
    removeBudget(item_id, idx){
      // let budgetDB = rtdb.ref(`/budget/${item_id}`).remove()
      if (idx > -1) {
         this.budget.splice(idx, 1);
      }
    },
    costBreakDown(){
      if(this.cost_frame == 'Annual'){
        this.denominator = 12
        this.cost_frame = 'Monthly'
      }else if(this.cost_frame == 'Monthly'){
        this.denominator = 365
        this.cost_frame = 'Daily'
      }else if(this.cost_frame == 'Daily'){
        this.denominator = 1
        this.cost_frame = 'Annual'
      }
    }
  },
  mounted(){
    // this.getBudgetData()
  }
}
</script>
<style scoped>
#Budgeting{
  width: 100%;
  margin: 1rem 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.filters{
  width: 100%;
  display: flex;
  font-weight: bold;
  align-items: center;
  margin: 1rem 0;
}
.budget-list{
  /* min-height: 40vh; */
  margin-bottom: 2rem;
}
.entry{
  /* display: flex;
  justify-content: flex-start;
  align-items:center; */
}
.entry span{
  margin: 0 1rem;
}
.cell{
  padding: .5rem 1rem;
  border-radius: 10px;
  color: #000;
  font-weight: 600;
  border: 2px solid #777;
}
.cell1{
  padding: 0;
}
.cell-cash{
  text-align: right;
  font-weight:bold;
}
.input-cell{
  width: 90%;
  height: 100%;
  font-size: 1rem;
  padding: .5rem 0;
  padding-left: 1rem;
  margin: 0 .1rem;;
  border: none;
}
.cell2{
  border: none;
}
.remove-btn{
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  font-size: 1.5rem;
  cursor:pointer;
  background-color: #fca6b1;
  border: 2px solid #cf0c26;
  color: #cf0c26;
  font-weight: bold;
}
.add-btn{
  border: 2px solid #0bb043;
  background-color: #b1e2c2;
  display: flex;
  align-items:center;
  justify-content:center;
  padding: .5rem;
}
.add-btn img{
  width: 1.2rem;
}
.remove-btn:hover{
  opacity: .7;
}
@media only screen and (max-width: 900px) {
  .non-mobile{
    display: none;
  }
}
</style>
