<template>
  <div id="Home">
    <div  class="nav-sidebar">
      <div class="top-sidebar" v-if="!isMobile">
        <img src="@/assets/logo.png" class="logo-text"/>
        <h2>Admin Portal</h2>
        <small>{{version_number}}</small>
      </div>
      <div class="sidebar-menu">
        <div v-for="(mu, idx) in menu" :key="idx" class="sidebar-link"
        @click="gotoRoute(mu.route)"
        :class="{'current-route': mu.route == $route.name}">
          <img :src="mu.icon" class="link-icon" />
          <p>{{mu.name}}</p>
        </div>
      </div>
    </div>

    <div class="dashboard-area">
      <router-view :isMobile="isMobile"/>
    </div>
    <!-- <WhatNewModal v-if="whats_new_modal" @closeModal="toggleWhatsNew" /> -->

  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
// import WhatNewModal from '@/components/topbar/WhatNewModal.vue'
export default {
  name: 'Home',
  props:['isMobile'],
  components:{
    // WhatNewModal
  },
  data(){
    return{
      photo:'https://www.psypost.org/wp-content/uploads/2017/08/meditating-woman-1200x900.jpg',
      profile:{
        photo:'https://www.psypost.org/wp-content/uploads/2017/08/meditating-woman-1200x900.jpg',
        name: 'Your Name'
      },
      search_query:'',
      whats_new_modal : false,
      menu: this.$store.state.sideMenu
    }
  },
  methods:{
    toggleWhatsNew(val){
      this.whats_new_modal = val
    },
    gotoRoute(route){
      this.$router.push({name: route})
    }
  },
  computed:{
    ...mapState(['version_number']),
    ...mapGetters([
      'menus'
    ]),
  },
  created(){
    // console.log(this.menu)
  }
}
</script>
<style scoped>
a{
    text-decoration: none;
}
.logo-text{
  width: 5rem;
  height: 5rem;
  margin: auto;
}
.nav-sidebar{
  background-color: var(--primary);
  background-color: #444;
  color: #fff;
  min-width: 7rem;
  margin:0;
  height: 100%;
  padding: 2rem 0;
  position: fixed;
  left:0;
  z-index: 100;
  transition: 1s;
  text-align: center;
}
.nav-sidebar h2{
  font-size: 1.2rem;
}
.sidebar-menu{
  background-color: #444;
  margin: 2rem 0;
  min-width: 10rem;
  display:flex;
  flex-direction: column;
  text-align:center;
  align-items:center;
  justify-content: center;
}
.sidebar-link a{
  color: #fff;
  margin: 0 auto;
}
.sidebar-link:hover{
  background-color: #fff;
}
.sidebar-link{
  cursor:pointer;
  display:flex;
  width: 100%;
  justify-content: flex-start;
  align-items:center;
  font-size: 1rem;
  font-weight: bold;
  transition:1s;
  color: #fff;
}
.sidebar-link p{
  display: none;
}
.sidebar-link a {
  padding: 1rem 0.3rem;
}
.link-icon{
  width: 3rem;
  transition:1s;
  margin: 2rem auto;
}
.account-box{
  position: absolute;
  height: 6rem;
  bottom: 4.2rem;
  width: 100%;
  left: 0;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -webkit-box-shadow: 0px -5px 22px -14px rgba(0,0,0,0.77);
  -moz-box-shadow: 0px -5px 22px -14px rgba(0,0,0,0.77);
  box-shadow: 0px -5px 22px -14px rgba(0,0,0,0.77);
}
.account-box a{
  text-align: center;
  margin-left: 2rem;
}
.account-photo {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  overflow:hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.current-route{
  background-color: var(--primary);
}

.dashboard-area{
  position:relative;
  margin-left: 10rem;
  padding: 2rem;
}

@media only screen and (max-width: 600px) {
  .nav-sidebar{
    width: 100%;
    margin:0;
    height: 6rem;
    padding: 0;
    right: 0;
    bottom: 0;
    left:0;
  }
  .nav-sidebar h2{
    font-size: 1rem;
  }
  .sidebar-menu{
    margin: 0;
    min-width: 10rem;
    display:flex;
    flex-direction: row;
    text-align:center;
    align-items:center;
    justify-content: center;
  }
  .dashboard-area{
    position:relative;
    margin: 0;
    padding: 1rem;
    margin-bottom: 7rem;
  }
}
</style>
