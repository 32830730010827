<template>
  <div id="Settings">
    <div class="dashboard-header">
      <h2>Settings</h2>
    </div>
    <p>Coming Soon</p>

  </div>
</template>

<script>
export default {
  name: 'Settings',
  components:{
  },
  data(){
    return{
      search_query:''
    }
  }
}
</script>
<style scoped>
</style>
